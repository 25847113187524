// import { getAddress } from '@ethersproject/address';

/**
 * Validates an address and returns the parsed (checksummed) version of that address
 * @param address the unchecksummed hex address
 */
export function validateAndParseAddress(address: string): string {
  return address;
  /* try {
    return getAddress(address);
  } catch (error) {
    throw new Error(`${address} is not a valid address.`);
  } */
}
